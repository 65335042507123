import type { UseFetchOptions } from '#app'
import type { MaybeRefOrGetter } from 'vue'
import { defu } from 'defu'
import type { ApiResponse } from '~/types/api.type'
import type { Client } from '~/types/client.type'
import type { MappedOmit } from '~/types/common.type'
import type { Employee } from '~/types/employee.type'
import type { Tool } from '~/types/tool.type'

export interface ApiEmployeeGetListParams {
  clientId?: number
  projectId?: number
  onlyManaged?: boolean
}

export interface ApiGetEmployeeByIdParams {
  withProjects?: never
  withManagedProjects?: never
  withSkills?: never
  withLatestWinningMeme?: never
}

export type ApiEmployeeCreateEmployee = MappedOmit<Employee, 'id'> & {
  employees: Employee['id'][]
  clientId: Client['id']
  leads: Employee['id'][]
  tools: Tool['id'][]
  notificationThresholds: unknown[]
}

export function useApiEmployeeGetList<T extends ApiEmployeeGetListParams>(params: MaybeRefOrGetter<T> = {} as T, options: UseFetchOptions<ApiResponse<Employee[]>> = {}) {
  const notOverridableOptions: UseFetchOptions<ApiResponse<Employee[]>> = { method: 'GET', params: toValue(params) }
  return useApiFetch('/employees', defu(notOverridableOptions, options))
}

export function useApiEmployeeGetById(id: MaybeRefOrGetter<Employee['id']>, options: UseFetchOptions<ApiResponse<Employee>> = {}) {
  const notOverridableOptions: UseFetchOptions<ApiResponse<Employee>> = { method: 'GET' }
  return useApiFetch(`/employees/${toValue(id)}`, defu(notOverridableOptions, options))
}

export function useApiEmployeeCreate(employee: MaybeRefOrGetter<ApiEmployeeCreateEmployee>, options: UseFetchOptions<ApiResponse<Employee>> = {}) {
  const notOverridableOptions: UseFetchOptions<ApiResponse<Employee>> = { method: 'POST', body: toValue(employee) }
  const overridableOptions: UseFetchOptions<ApiResponse<Employee>> = { immediate: false, watch: false }
  return useApiFetch('/employees', defu(notOverridableOptions, options, overridableOptions))
}

export function useApiEmployeeUpdateById(id: MaybeRefOrGetter<Employee['id']>, employee: MaybeRefOrGetter<ApiEmployeeCreateEmployee>, options: UseFetchOptions<ApiResponse<Employee>> = {}) {
  const notOverridableOptions: UseFetchOptions<ApiResponse<Employee>> = { method: 'PATCH', body: employee }
  const overridableOptions: UseFetchOptions<ApiResponse<Employee>> = { immediate: false, watch: false }
  return useApiFetch(`/employees/${toValue(id)}`, defu(notOverridableOptions, options, overridableOptions))
}

export function useApiEmployeeDeleteById(id: MaybeRefOrGetter<Employee['id']>, options: UseFetchOptions<never> = {}) {
  const notOverridableOptions: UseFetchOptions<never> = { method: 'DELETE' }
  const overridableOptions: UseFetchOptions<never> = { immediate: false, watch: false }
  return useApiFetch(`/employees/${toValue(id)}`, defu(notOverridableOptions, options, overridableOptions))
}
